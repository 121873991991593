
export default {
  name: 'EmsTwo',
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },
}
