
import { VueAgile } from 'vue-agile'
import { format } from 'date-fns'
export default {
  name: 'SimpleCarousel',
  components: {
    VueAgile,
  },
  filters: {
    removeTime(value) {
      if (!value) return value
      const date = new Date(value?.split(' ')[0])
      return format(date, 'Y / MM / dd')
    },
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      options: {
        infinite: true,
        slidesToShow: 1,
        navButtons: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1310,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        if (
          this.$refs.agileCarousel.$refs.nextButton &&
          this.$refs.agileCarousel.$refs.prevButton
        ) {
          try {
            this.$refs.agileCarousel.$refs.nextButton.setAttribute(
              'aria-label',
              'Next slide',
            )
            this.$refs.agileCarousel.$refs.prevButton.setAttribute(
              'aria-label',
              'Previous slide',
            )
          } catch (ex) {
            console.error('SimpleCarousel, mounted error: label missing')
          }
        }
      })
    })
  },
  methods: {
    getThumb(item) {
      return item.media?.extras?.e_840xAuto?.webp?.src || '/images/no-image.jpg'
    },
    getSlug(item) {
      return item.current_url?.full_url || '#'
    },
  },
}
